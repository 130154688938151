import { useEffect, useMemo, useState, useCallback } from "react";
import AuthContext from "./authContext";
import { LocalStorageHelper } from '../../utils/BroswerStorageHelpers'
import { fetcher } from "../../utils/api";

export const initialLoginState: AuthStateType = {
  _id: "",
  name: "",
  email: "",
  role: "",
};

const AuthState = (props: { children: React.ReactNode }) => {
  const { children } = props;

  const [state, setState] = useState<AuthStateType>(initialLoginState);
  const { setItem, getItem, removeItem } = LocalStorageHelper();

  const login = useCallback((data: AuthStateType) => {
    setItem('userData', JSON.stringify(data));
    setState(data)
  }, [setItem]);

  const logout = useCallback(async() => {
    console.log('Log oout is running')
    const res = await fetcher(`/auth/logout`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    
    if(res) {
      removeItem('userData');
      setState(initialLoginState);
    }
  }, [removeItem]);

  useEffect(() => {
    const userData = getItem("userData") || "";
    const parsedUserData = userData ? JSON.parse(userData) : null;

    if (parsedUserData) {
      setState({
        _id: parsedUserData?._id || "",
        name: parsedUserData?.name || "",
        email: parsedUserData?.email || "",
        role: parsedUserData?.role || ""
      });
    }
  }, [getItem]);

  const stateValue = useMemo(() => ({ state, setState, login, logout }), [state, setState, login, logout]);

  return (
    <AuthContext.Provider value={stateValue}> {children}</AuthContext.Provider>
  );
};

export default AuthState;
