export default function NotFoundPage() {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center px-6">
      <div className="text-center">
        <h1 className="text-9xl font-extrabold text-gray-800 mb-4">404</h1>
        <h2 className="text-2xl md:text-3xl font-semibold text-gray-600 mb-4">
          Oops! Page not found.
        </h2>
        <p className="text-gray-500 text-lg mb-6">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <a
          href="/"
          className="bg-primary text-white px-6 py-3 rounded-lg text-lg font-medium shadow hover:bg-primary-dark transition duration-300"
        >
          Go Back Home
        </a>
      </div>
    </div>
  );
}