
export const API_URL = process.env.REACT_APP_BASE_API;

console.log('api url', API_URL)

export const fetcher = async (url: string, options: any = {}) => {
  const res = await fetch(`${API_URL}${url}`, {...options, credentials: 'include'});
  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.message);
  }
  return res.json();
};