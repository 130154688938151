import React, { JSX, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'src/components/ui/loader/loader';
import { fetcher } from 'src/utils/api';

interface ProtectedRouteProps {
  path?: string;
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const verifyAuth = async () => {
      try {
        const response = await fetcher(`/auth/verify`, {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' }
            });
        if (response.message === 'Authorized') {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    verifyAuth();
  }, []);

  if (isAuthenticated === null) {
    return <Loader/>; // Add a spinner or placeholder while checking auth
  }

  return isAuthenticated ? (
    <>
      {element}
    </>
  ) : (
    <Navigate to="/auth" replace />
  );
};

export default ProtectedRoute;