import React from 'react'
import './loader.css'

function Loader() {
  return (
    <div className='zoom-container'>
      <img src="/logo-icons/logo.webp" alt="Avawatz Logo" width={300}/>
    </div>
  )
}

export default Loader