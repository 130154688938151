import { fetcher } from '../../utils/api';
import React from 'react'

export default function ViewPendingInvitationsModal({pendingInvitations, setIsModalOpen, setPendingInvitations}: any) {
  
  const deletePendingInvitations = async (pendingInvitationId: string) => {
    try{
      const res = await fetcher(`/teams/deletePendingInvitations/${pendingInvitationId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      if(res) {
        const filteredPendingInvitations = pendingInvitations.filter((x:any) => x._id !== pendingInvitationId)
        setPendingInvitations(filteredPendingInvitations)
      }
    }catch(error) {
      console.log('error', error)
    }
    
  }
  
  
  return (
    <div>
      <h2 className="text-lg font-bold mb-4">Pending Invitations</h2>
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="border px-4 py-2 text-sm font-semibold">Name </th>
            <th className="border px-4 py-2 text-sm font-semibold">Invited By</th>
            <th className="border px-4 py-2 text-sm font-semibold">AvaSwift AI Code</th>
            <th className="border px-4 py-2 text-sm font-semibold">Actions</th>
          </tr>
        </thead>
        <tbody>
          {pendingInvitations?.map((x: any) => (
            <tr key={x._id} className='text-sm text-gray-500 font-semibold'>
              <td className="border px-4 py-2">{x.invitedUser}</td>
              <td className="border px-4 py-2">{x.invitedBy.email}</td>
              <td className="border px-4 py-2">{x.invitationCode}</td>
              <td className="border px-4 py-2">
                <button
                  className="px-2 py-1 bg-white rounded font-bold text-red-500"
                  onClick={() => deletePendingInvitations(x._id)}
                >
                  DELETE
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="px-4 py-2 mt-5 text-sm font-bold bg-gray-900 text-white rounded"
        onClick={() => setIsModalOpen({open: false, modalType:''})}
      >
        Close
      </button>
    </div>
  )
}
